import request from '@/utils/request'

const table={
  // 首页信息
  indexedDB(data) {
    return request({
      url: '/clairvoyance/sms/index/index',
      method: 'post',
      data
    })
  },
  // 门店-列表
  getShopList(data) {
    return request({
      url: '/clairvoyance/sms/default/shop-list',
      method: 'post',
      data
    })
  },
  // 切换门店
  changeNodeId(data) {
    return request({
      url: '/clairvoyance/boss/default/change-node-id',
      method: 'post',
      data
    })
  },
  // 群组列表
  memberGroup(data) {
    return request({
      url: '/clairvoyance/sms/member/member-group-list',
      method: 'post',
      data
    })
  },
  // 分类-列表
  categoryList(data) {
    return request({
      url: '/clairvoyance/sms/category/list',
      method: 'post',
      data
    })
  },
  // 短信-列表
  templateList(data) {
    return request({
      url: '/clairvoyance/sms/template/list',
      method: 'post',
      data
    })
  },
  // 短信-新增
  templateAdd(data) {
    return request({
      url: '/clairvoyance/sms/template/add',
      method: 'post',
      data
    })
  },
  // 记录列表
  recordList(data) {
    return request({
      url: '/clairvoyance/sms/record/list',
      method: 'post',
      data
    })
  },
  // 分类-新增
  categoryAdd(data) {
    return request({
      url: '/clairvoyance/sms/category/add',
      method: 'post',
      data
    })
  },
  // 分类-删除
  categoryDelete(data) {
    return request({
      url: '/clairvoyance/sms/category/delete',
      method: 'post',
      data
    })
  },
  // 分类-编辑
  categoryEdit(data) {
    return request({
      url: '/clairvoyance/sms/category/edit',
      method: 'post',
      data
    })
  },
  // 短信-删除
  templateDelete(data) {
    return request({
      url: '/clairvoyance/sms/template/delete',
      method: 'post',
      data
    })
  },
  // 群组-新增
  memberGroupAdd(data) {
    return request({
      url: '/clairvoyance/sms/member/member-group-add',
      method: 'post',
      data
    })
  },
  // 会员卡类型
  memberCardType(data) {
    return request({
      url: '/clairvoyance/sms/member/member-card-type',
      method: 'post',
      data
    })
  },
  // 短信-详情
  templateGet(data) {
    return request({
      url: '/clairvoyance/sms/template/get',
      method: 'post',
      data
    })
  },
  // 群组-删除
  memberGroupDel(data) {
    return request({
      url: '/clairvoyance/sms/member/member-group-del',
      method: 'post',
      data
    })
  },
  // 定时任务-列表
  crontabList(data) {
    return request({
      url: '/clairvoyance/sms/crontab/crontab-list',
      method: 'post',
      data
    })
  },
  // 节假日提醒
  holidayList(data) {
    return request({
      url: '/clairvoyance/sms/crontab/holiday-list',
      method: 'post',
      data
    })
  },
  // 定时任务-新建
  crontabAdd(data) {
    return request({
      url: '/clairvoyance/sms/crontab/crontab-add',
      method: 'post',
      data
    })
  },
  // 定时任务-删除
  crontabDelete(data) {
    return request({
      url: '/clairvoyance/sms/crontab/crontab-del',
      method: 'post',
      data
    })
  },
  // 定时任务-编辑
  crontabEdit(data) {
    return request({
      url: '/clairvoyance/sms/crontab/crontab-edit',
      method: 'post',
      data
    })
  },
  // 即时发送
  currSend(data) {
    return request({
      url: '/clairvoyance/sms/send/curr-send',
      method: 'post',
      data
    })
  },
  // 会员-数量
  memberNum(data) {
    return request({
      url: '/clairvoyance/sms/member/member-num',
      method: 'post',
      data
    })
  },
  // 获取详情
  crontabDetail(data) {
    return request({
      url: '/clairvoyance/sms/crontab/detail',
      method: 'post',
      data
    })
  }
}

export default table
