import axios from 'axios'
import { Toast,Dialog } from 'vant'
import store from "../store/"
import { baseURL } from '@/utils/config'


const service = axios.create({
  // baseURL: '/dev-api',
  baseURL,
  timeout: 8000
})

// 添加请求拦截器--------------------------------------------
service.interceptors.request.use(
  config => {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      mask: false,
      duration:0
    })
    // 判断是否有 token
    if(store.state.userStatus.token){config.headers['access-token'] = store.state.userStatus.token}
    return config
  },
  error => {
    Toast.clear()
    console.log(error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器-------------------------------------------
service.interceptors.response.use(
  response => {
    Toast.clear()
    const res = response.data
    if (res.code !== 0) {
      switch(res.code){
        case 1001:
          // 点击确认重新获取token
          let openid=store.state.openid
          Dialog.alert({title: '提示',message: `${res.msg}`}).then(() => {location.href=location.protocol+'//'+location.host+'/'+'?openid='+openid})
          break;
        case 8001:
          location.href=location.protocol+'//'+location.host+'/payIndex?key=boss'
          break;
        case 8002:
          location.href=location.protocol+'//'+location.host+'/payIndex?key=boss'
          break;
        default:
          Toast.fail(`${res.msg}`)
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Toast.clear()
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export default service
